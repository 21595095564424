import React from 'react';
import "../scss/main.scss"
import Header from '../components/header';
import Main from '../components/main';
import PageHeader from '../components/Organism/PageHeader/PageHeader';
import { Helmet } from 'react-helmet';
import Footer from '../components/Footer/Footer';
import StartWork from '../components/forms/StartWork';

function Contacts(props) {
    const form = <StartWork/>;
    return (
        <>
            <Helmet
                title="Контакты студии разработки сайтов Androsov Digital" 
                meta={
                [
                    {
                    name:"description",
                    content:"Разработка сайтов с высокой конверсией. Огромный опыт и соблюдение сроков гарантированно!"
                    }
                ]
                }
            /> 
            <Header/>
            <Main>
                <PageHeader h1={"Как с нами связаться"}/>
            </Main>
            <Footer/>
        </>
    );
}

export default Contacts;